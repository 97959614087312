<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body">
            <div class="row">
                <div class="col-12 col-lg-6 g">
                    <button class="btn btn-block btn-success" @click="between = 1;  reports = []">
                        <i class="fa fa-calendar-o"></i>
                        تقرير يوم
                    </button>
                </div>
                <div class="col-12 col-lg-6 g">
                    <button class="btn btn-block btn-primary" @click="between = 2; reports = []">
                        <i class="fa fa-calendar"></i>
                        تقرير فترة
                    </button>
                </div>
            </div>
            <hr v-if="between != 0">
            <div v-if="between != 0">
                <div class="form-group" v-if="between == 1">
                <h5 for="">التاريخ</h5>
                <input type="date"
                    class="form-control form-control-lg" v-model="date">
                </div>
                <div class="form-group" v-if="between == 2">
                <h5 for="">الفترة من</h5>
                <input type="date"
                    class="form-control form-control-lg" v-model="from">
                </div>
                <div class="form-group" v-if="between == 2">
                <h5 for="">الفترة الى</h5>
                <input type="date"
                    class="form-control form-control-lg" v-model="to">
                </div>
                <span>اختر الفصل</span>
                <select class="form-control" v-model="classroom">
                    <option value="">-- الكل --</option>
                    <option v-for="c in classes" :key="c._id" :value="c.classname + ',' + c.classroom">
                        {{ c.classname }} - {{ c.classroom }}
                    </option>
                </select>
                <br v-if="between == 1">
                <span for="" v-if="between == 1">عرض من لديه حضور او غياب</span>
                <div class="form-group" v-if="between == 1">
                <select name="" class="form-control" v-model="only">
                    <option value="all">الكل</option>
                    <option value="attend">من لديه حضور</option>
                    <option value="ghiab">من لديه غياب</option>
                    <option value="attend2">من لديه حضور وليس لديه غياب</option>
                    <option value="ghiab2">من لديه غياب وليس لديه حضور</option>
                </select>
                </div>
                <div class="col-12 text-center g">
                    <br>
                    <button class="btn btn-primary" @click="search()">
                        عرض التقرير
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 c g" v-if="between != 0 && reports.length">
        <div class="card card-body">
            <div class="col-12">
                <button class="btn btn-sm btn-warning hidemex" style="border-radius: 0px;" @click="printTable()">
                    <i class="fa fa-print"></i>
                    طباعة الجدول
                </button>
                &nbsp;
                <button class="btn btn-sm btn-success hidemex" style="border-radius: 0px;" v-b-modal.send v-if="between == 1">
                    <i class="fa fa-paper-plane"></i>
                   ارسال لاولياء الامور
                </button>
            </div>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered table-striped" id="t" v-if="between == 1">
                    <thead>
                        <th class="dnone">
                            م
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('name')">الاسم <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('number')">رقم الهوية <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('attend')">الحضور <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('ghiab')">الغياب <i class="fa fa-sort"></i></a>
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(report, i) in reports" :key="report._id">
                            <td class="dnone">
                                {{ i+1 }}
                            </td>
                            <td>
                                {{ report.name }}
                            </td>
                            <td>
                                {{ report.number }}
                            </td>
                            <td>
                                <span v-for="l in report.attend" v-b-tooltip.hover.v-primary :title="l.teacher + ' في ' + l.time" :key="l.lesson" class="btn btn-sm btn-success" style="border-radius: 0px; padding: 5px; border: none !important;">
                                    {{ l.lesson }}
                                </span>
                            </td>
                            <td>
                                <span v-for="l in report.ghiab" v-b-tooltip.hover.v-primary :title="l.teacher + ' في ' + l.time" :key="l.lesson" class="btn btn-sm btn-danger" style="border-radius: 0px; padding: 5px; border: none !important;">
                                    {{ l.lesson }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-hover table-sm table-bordered table-striped" id="t" v-if="between == 2">
                    <thead>
                        <th class="dnone">
                            م
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('name')">الاسم <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('number')">رقم الهوية <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('attend')">اجمالي الحضور <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('ghiab')">اجمالي الغياب <i class="fa fa-sort"></i></a>
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(report, i) in reports" :key="report._id">
                            <td class="dnone">
                                {{ i+1 }}
                            </td>
                            <td>
                                {{ report.name }}
                            </td>
                            <td>
                                {{ report.number }}
                            </td>
                            <td>
                                {{ report.attend }}
                            </td>
                            <td>
                                {{ report.ghiab }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="send" :title="'ارسال لاولياء الامور'" hide-footer>
       <div class="form-group">
         <span for="">الرسالة</span>
         <textarea class="form-control" v-model="message" rows="3"></textarea>
       </div>
       <div class="row">
        <div class="col-12 col-lg-4 g">
            <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                <i class="fa fa-whatsapp"></i>
                    واتس تلقائي
            </button>
        </div>
        <div class="col-12 col-lg-4 g">
            <button class="btn btn-success btn-block" @click="send('wa')">
                <i class="fa fa-whatsapp"></i>
                اداة المتصفح
            </button>
        </div>
        <div class="col-12 col-lg-4 g">
            <button class="btn btn-primary btn-block"  @click="send('sms')">
                <i class="fa fa-mobile"></i>
                SMS
            </button>
        </div>
       </div>
    </b-modal>
  </div>
</template>

<script>
import { BFormCheckbox, VBModal, BModal, VBTooltip, BTooltip, } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BTooltip,
        BModal
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            reports: [],
            date: new Date().toISOString().split("T")[0],
            classes: [],
            classrooms: [],
            classroom: "",
            only: "all",
            q: "",
            between: 0,
            message: `تقرير غياب الحصص لابنكم {name}
بتاريخ {date}
الحضور: {attend}
الغياب: {ghiab}`,
            from: new Date().toISOString().split("T")[0],
            to: new Date().toISOString().split("T")[0],
            xa: -1,
            xb: 1,
            last_sort: "f",
        }
    },
    created(){
        if(!checkPer("lessons")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    a.classrooms.forEach(function(b){
                        g.classes.push({
                            classname: a.title,
                            classroom: b
                        })
                    })
                })
            }
        }).fail(function(){
        })
    },
    methods: {
        ordertable(m){
            var x = this.reports, g = this;
            if(g.last_sort == m){
                g.xa = g.xa == 1 ? -1 : 1;
                g.xb = g.xb == -1 ? 1 : -1;
            }
            g.last_sort = m;
            function compare( a, b ) {
                if ( a[m] < b[m] ){
                    return g.xa;
                }
                if ( a[m] > b[m] ){
                    return g.xb;
                }
                return 0;
            }
            x.sort(compare);
            this.reports = []
            var g = this;
            setTimeout(() => {
                g.reports = JSON.parse(JSON.stringify(x))
            }, 100);
        },
        send(method){
            var g = this;
            var messages = [];
            g.reports.forEach(function(a){
                if(a.attend.length > 0 || a.ghiab.length > 0){
                    messages.push({
                        phones: a.phones,
                        message: g.message.replace("{name}", a.name).replace("{date}", g.date).replace('{attend}', a.attend.map(function(x){
                            return 'الحصة ' + x.lesson
                        }).join(',')).replace('{ghiab}', a.ghiab.map(function(x){
                            return 'الحصة ' + x.lesson
                        }).join(','))
                    })
                }
            })
            if(method == 'wa'){
                $("#sendmodalwaarray").val(JSON.stringify(messages))
                $("#sendmodalwatype").val("lessons")
                $("#sendWAModal").click()
            }else{
                if(!confirm(`متأكد من ارسال ${messages.length} رسالة؟`)){return false;}
                $.post(api + '/user/messages/send-arr', {
                    jwt: g.user.jwt,
                    arr: JSON.stringify(messages),
                    method: method,
                    type: "lessons"
                }).then(function(r){
                    g.loaded = true;
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            }
        },
        search(){
            var g = this;
            $.post(api + '/user/lessons/report' + (g.between  == 2 ? '-between' : ''), {
                jwt: g.user.jwt,
                classroom: this.classroom,
                date: this.date,
                from: this.from,
                only: this.only,
                to: this.to
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.reports = r.response;
                }
            }).fail(function(){
            })
        },
        printTable(){
            var win = window.open("", "")
            var html = $("#t").parent().html();
            win.document.write(`<html dir='rtl'><style>
                        table, td, th {
                        border: 1px solid;
                        }
                        tr{
                            text-align: center
                        }
                        table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th{
                        background: #eee
                    }
                    .hidemex{
                        display:none
                    }
                </style>
                ${html}</html>`)
            win.document.close()
            setTimeout(() => {
                win.print()
            }, 1000);
        }
    }
}
</script>